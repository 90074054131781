@import '~antd/dist/antd.css';

.form {
    margin: 0 auto;
    max-width: 700px;
    position: relative;
    transition: 0.3s max-width;
    will-change: max-width;
    transform: translateZ(0);
  }
  
  .form label {
    text-transform: capitalize;
  }
  
  .form--history {
    max-width: 940px;
  }
  
  .form__tab.--error {
    color: red;
  }
  
  .form__error {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 7px;
    margin-bottom: 2px;
  }
  
  .form__spin {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 300px;
    width: 100%;
  }
  
  .form__spin--overlay {
    background-color: rgba(255, 255, 255, .7);
    height: 100%;
    position: absolute;
    z-index: 20;
  }
