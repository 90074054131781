.input-translations {
  width: 100%;
}

.input-translations .ant-input-group-addon {
  min-width: 3em;
}

.ant-input-group-wrapper {
  max-width: 36em;
  float: right;
}

.input-translations .ant-input.input-translations__input {
  max-width: 36em;
  float: right;
}

.--input-translations .has-error>.ant-form-explain {
  display: none;
}

.--input-translations .has-error .ant-input {
  border-color: #d9d9d9;
}

.--input-translations .has-error .ant-input:hover {
  border-color: #40a9ff;
}

.--input-translations .has-error .ant-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.--input-translations .has-error .ant-input[name='tranlations-en'],
.--input-translations .has-error .ant-input[name='tranlations-en']:hover {
  border-color: #f5222d;
}

.--input-translations .has-error .ant-input[name='tranlations-en']:focus {
  border-color: #f5222d;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}

.--input-translations .has-error .ant-input-group-addon {
  background-color: #fafafa;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}
