@import '~antd/dist/antd.css';
body {
    padding: 0;
    margin: 0 !important;
    min-width: 1280px;
  }
  
  .ant-table h1 {
    margin-bottom: 0;
  }
  
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  }
  
  .custom-filter-dropdown input {
    width: 130px;
    margin-right: 8px;
  }
  
  .custom-filter-dropdown button {
    margin-right: 8px;
  }
  
  .highlight {
    color: #f50;
  }
  
  .ant-spin-nested-loading > div {
    position: static;
  }
  
  .ant-spin-nested-loading > div > div.ant-spin {
    max-height: 520px;
  }
  
  div.ant-spin-blur {
    filter: blur(0.3px);
    opacity: .3;
  }
  
  .logo {
    margin: 17px 0px 16px 0;
    float: left;
  }
  
  ul.ant-menu-dark {
    background: #4196ec;
  }
  
  ul.ant-menu-dark .ant-menu-submenu-selected,
  ul.ant-menu-dark .ant-menu-submenu-active {
    color: white !important;
  }
  
  ul.ant-menu.ant-menu-dark .ant-menu-item-selected,
  ul.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;
  }
  
  ul.ant-menu.ant-menu-sub .ant-menu-item-selected {
    background-color: #1890ff;
  }
  
  .ant-btn {
    line-height: 1.2;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: white !important;
  }
  
  .ant-empty {
    padding-top: 15px;
  }
